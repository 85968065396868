export const useInput = () => {
  const lettersOnly = (event) => {
    const charCode = (event.which) ? event.which : event.keyCode
    if(!((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123 || charCode == 32))) { 
      event.preventDefault(); 
    }
  }

  return { lettersOnly }
}
