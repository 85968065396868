<template>
  <div>
    <h1 class="h3 mb-4">
      {{ $t('withdrawal.distributor_wallet') }}
    </h1>
    <a-card v-if="$store.state.user.restriction_base !== 'Business' && $store.state.user.role_name !== 'Principle'">
      <div class="d-flex align-items-center">
        <div>
          <h5 class="fw-bold">
            {{ `${$t('withdrawal.total_balance')}:` }}
            <span :class="balance.available > 0 ? 'c-green' : 'c-red'">
              {{ `${format(balance.available, 'IDR')}` }}
            </span>
          </h5>
          <p class="mb-0">
            {{ `${$t('withdrawal.estimated_balance')}:` }}
            <span :class="balance.estimated > 0 ? 'c-green' : 'c-red'">
              {{ `${format(balance.estimated, 'IDR')}` }}
            </span>
          </p>
          <p class="mb-0 mt-1 disclaimer-balance">
            *{{ `${$t('withdrawal.disclaimer_balance')}` }}
          </p>
        </div>
        <!-- <div class="ml-auto">
          <a-button
            type="primary"
            size="large"
            :disabled="isLoading"
            @click="onOpenRequest"
          >
            {{ $t('withdrawal.withdraw_wallet') }}
          </a-button>
        </div> -->
        <div class="ml-auto">
          <a-button
            type="primary"
            size="large"
            :disabled="isLoading"
            @click="onViewHistoryWallet"
          >
            <a-icon type="history" />
            {{ $t('withdrawal.history_wallet') }}
          </a-button>
        </div>
      </div>
    </a-card>

    <div v-if="warehouseList.length > 0 && $store.state.user.restriction_base === 'Business' && $store.state.user.role_name === 'Principle'" class="row my-3">
      <div class="col-12 col-md-3">
        <a-select
          :placeholder="$t('productAvailability.gudang')"
          class="w-100"
          :value="valueWarehouse || undefined"
          @change="handleChangeWarehouse"
        >
          <a-select-option
            v-for="item in warehouseList"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </div>
    </div>

    <div class="product-availability__utils">
      <a-tabs :default-active-key="params.status || ''" @change="changeTab">
        <a-tab-pane key="" :tab="$t('withdrawal.all')" />
        <a-tab-pane key="DRAFT" :tab="$t('withdrawal.draft')" />
        <a-tab-pane key="APPROVED" :tab="$t('withdrawal.approved')" />
        <a-tab-pane key="REJECTED" :tab="$t('withdrawal.rejected')" />
        <a-tab-pane key="PROCESSED" :tab="$t('withdrawal.processed')" />
        <a-tab-pane key="COMPLETED" :tab="$t('withdrawal.completed')" />
        <a-tab-pane key="FAILED" :tab="$t('withdrawal.failed')" />
        <!-- <a-tab-pane key="CANCELED" :tab="$t('withdrawal.canceled')" /> -->
        <!-- <a-tab-pane key="CONFIRMED" :tab="$t('withdrawal.confirmed')" /> -->
      </a-tabs>
    </div>

    <div class="d-flex align-items-center mt-4">
      <h6 class="mb-0 fw-bold">
        {{ $t('withdrawal.withdrawal_history') }}
      </h6>
      <div class="ml-auto">
        <div class="d-inline-block">
          <span class="mr-2">
            {{ $t('withdrawal.date') }}
          </span>
          <a-range-picker
            class="date-container"
            size="large"
            :ranges="ranges"
            :format="dateFormat"
            :placeholder="[
              $t('dashboardContent.start_date'),
              $t('dashboardContent.end_date'),
            ]"
            :disabled-date="disabledDate"
            :disabled="isLoading"
            allow-clear
            @change="onChangeDate"
          >
            <DateIcon slot="suffixIcon" style="color: #999999" />
          </a-range-picker>
        </div>
        <!-- <div class="d-inline-block ml-4">
          <span class="mr-2">Status</span>
          <a-select
            v-model="params.status"
            size="large"
            style="width: 150px"
            class="text-capitalize select-antd-default"
            :placeholder="$t('withdrawal.status')"
            :options="status"
            :disabled="isLoading"
            @change="fetchWithdrawal"
          />
        </div> -->
      </div>
    </div>
    <div class="mt-2">
      <a-table
        class="withdrawal-list"
        :data-source="withdrawals"
        :row-key="record => record.id"
        :columns="columns"
        :loading="isLoading"
        :pagination="false"
      >
        <template slot="created_at" slot-scope="text">
          {{ parseFullDate24(text) }}
        </template>
        <template slot="amount" slot-scope="text">
          {{ format(text * -1, 'IDR') }}
        </template>
        <template slot="status" slot-scope="text">
          <a-tag :color="colors[text]">
            {{ $t(`withdrawal.${text?.toLowerCase()}`) }}
          </a-tag>
        </template>
        <div slot="expandedRowRender" slot-scope="record" class="detail-withdrawal w-100">
          <a-row :gutter="16">
            <a-col :span="4">
              {{ $t('withdrawal.request_id') }}
            </a-col>
            <a-col :span="20">
              : {{ record.id || '-' }}
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="4">
              {{ $t('withdrawal.bank_details') }}
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="1">
            &nbsp;
            </a-col>
            <a-col :span="23">
              <a-row :gutter="16">
                <a-col :span="4">
                  {{ $t('withdrawal.bank_name') }}
                </a-col>
                <a-col :span="20">
                  : {{ record.bank_name || '-' }}
                </a-col>
              </a-row>
              <a-row :gutter="16">
                <a-col :span="4">
                  {{ $t('withdrawal.account_number') }}
                </a-col>
                <a-col :span="20">
                  : {{ record.account_number || '-' }}
                </a-col>
              </a-row>
              <a-row :gutter="16">
                <a-col :span="4">
                  {{ $t('withdrawal.benificiary_name') }}
                </a-col>
                <a-col :span="20">
                  : {{ record.account_name || '-' }}
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="4">
              {{ $t('withdrawal.processing_date') }}
            </a-col>
            <a-col :span="20">
              : {{ record.updated_at ? parseFullDate24(record.updated_at) : '-' }}
            </a-col>
          </a-row>
        </div>
      </a-table>
    </div>
    <div class="mt-4 text-right">
      <a-pagination
        v-model="params.page"
        :page-size="params.limit"
        :page-size-options="sizeOptions"
        :total="totalRows"
        :show-total="
          (total, range) =>
            $t('utils.pagination_show_total', {
              perpage: `${range[0]} - ${range[1]}`,
              total,
            })
        "
        :disabled="isLoading || !totalRows"
        show-size-changer
        @change="onPageChange"
        @showSizeChange="onShowSizeChange"
      />
    </div>

    <a-modal
      center
      :width="500"
      :footer="null"
      :mask-closable="false"
      :visible="visibleRequest"
      :title="$t('withdrawal.withdrawal_form')"
      @cancel="onCancelRequest"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
      >
        <a-skeleton :loading="isLoading">
          <div class="text-right">
            <h5 class="fw-bold">
              {{ `${$t('withdrawal.total_balance')}:` }}
              <span :class="balance.available > 0 ? 'c-green' : 'c-red'">
                {{ `${format(balance.available, 'IDR')}` }}
              </span>
            </h5>
          </div>
          <div style="position: relative">
            <span class="add-bank" @click="onAddBank">
              <a-icon type="plus" />
              Tambah Bank
            </span>
            <a-form-model-item
              :label="$t('withdrawal.bank_account')"
              prop="bank"
            >
              <a-select
                v-model="form.bank"
                size="large"
                class="w-100 select-antd-default"
                :placeholder="$t('withdrawal.select_bank')"
                :default-active-first-option="false"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                show-search
                allow-clear
                @change="onChangeAccount"
                @search="searchAccount"
              >
                <a-select-option
                  v-for="account in filteredAccount"
                  :key="account.id"
                >
                  {{
                    `${account.bank_name} - ${account.account_number} ${account.account_name}`
                  }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
          <a-form-model-item
            :label="$t('withdrawal.withdrawal_amount')"
            prop="amount"
          >
            <InputNumber
              v-model="form.amount"
              size="large"
              addon-before="Rp"
              :placeholder="$t('withdrawal.withdrawal_amount')"
              @change="checkAmount"
            />
            <div v-if="result < 0" class="ant-form-explain" style="color: #f5222e">
              Jumlah Penarikan tidak boleh lebih dari Saldo
            </div>
            <div v-if="parseInt(form.amount.replaceAll('.', '')) < withdrawalParameter?.min_amount" class="ant-form-explain" style="color: #f5222e">
              {{ $t('withdrawal.text_min_amount', { min_amount: format(withdrawalParameter?.min_amount, 'IDR') }) }}
            </div>
            <div v-else-if="parseInt(form.amount.replaceAll('.', '')) > withdrawalParameter?.max_amount" class="ant-form-explain" style="color: #f5222e">
              {{ $t('withdrawal.text_max_amount', { max_amount: format(withdrawalParameter?.max_amount, 'IDR') }) }}
            </div>
          </a-form-model-item>
          <ul class="pl-2 list-term">
            <li class="note">
              {{
                $t('withdrawal.terms1', {
                  max_frequency: withdrawalParameter?.max_frequency,
                  frequency_time_value: withdrawalParameter?.frequency_time_value,
                  frequency_time_unit: getMappingTimeUnit,
                })
              }}
            </li>
            <li class="note">
              {{ $t('withdrawal.terms2') }}
            </li>
            <li class="note">
              {{
                $t('withdrawal.terms3', {
                  min_amount: format(withdrawalParameter?.min_amount, 'IDR'),
                  max_amount: format(withdrawalParameter?.max_amount, 'IDR'),
                })
              }}
            </li>
          </ul>
          <a-form-model-item prop="correct">
            <a-checkbox-group v-model="form.correct">
              <a-checkbox value="1">
                {{ $t('withdrawal.data_correct') }}
              </a-checkbox>
            </a-checkbox-group>
          </a-form-model-item>
        </a-skeleton>
        <div class="mt-4 text-center">
          <a-button
            type="primary"
            size="large"
            class="mr-4"
            ghost
            :disabled="isLoading"
            @click="onCancelRequest"
          >
            {{ $t('utils.cancel') }}
          </a-button>
          <a-button
            type="primary"
            size="large"
            :loading="isLoading"
            @click="onConfirmRequest"
          >
            OK
          </a-button>
        </div>
      </a-form-model>
    </a-modal>

    <a-modal
      center
      :width="420"
      :footer="null"
      :mask-closable="false"
      :visible="visibleBank"
      title="Tambah bank"
      @cancel="onCancelBank"
    >
      <a-form-model ref="ruleBank" :model="formBank" :rules="rulesBank">
        <a-skeleton :loading="isLoading">
          <a-form-model-item :label="$t('withdrawal.bank_name')" prop="bank_id">
            <a-select
              v-model="formBank.bank_id"
              size="large"
              class="w-100 select-antd-default"
              :placeholder="$t('withdrawal.select_bank')"
              :default-active-first-option="false"
              :filter-option="false"
              :not-found-content="fetching ? undefined : null"
              label-in-value
              show-search
              allow-clear
              @change="onChangeBank"
              @search="searchBank"
            >
              <a-select-option v-for="bank in filteredBank" :key="bank.id">
                {{ bank.bank_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            :label="$t('withdrawal.account_number')"
            prop="account_number"
          >
            <a-input
              v-model.trim="formBank.account_number"
              size="large"
              autocomplete="off"
              :placeholder="$t('withdrawal.account_number')"
              allow-clear
              :max-length="25"
              @keypress="numberOnly"
            />
          </a-form-model-item>
          <a-form-model-item
            :label="$t('withdrawal.account_name')"
            prop="account_name"
          >
            <a-input
              v-model.trim="formBank.account_name"
              size="large"
              autocomplete="off"
              :placeholder="$t('withdrawal.account_name')"
              allow-clear
              :max-length="70"
              @keypress="lettersOnly"
            />
          </a-form-model-item>
        </a-skeleton>
        <div class="mt-4 text-center">
          <a-button
            type="primary"
            size="large"
            class="mr-4"
            ghost
            :disabled="isLoading"
            @click="onCancelBank"
          >
            {{ $t('utils.cancel') }}
          </a-button>
          <a-button
            type="primary"
            size="large"
            :loading="isLoading"
            @click="onSaveBank"
          >
            OK
          </a-button>
        </div>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { i18n } from '@/i18n'
import debounce from 'lodash/debounce'
import {
  addBank,
  addWithdrawal,
  getBalance,
  getBankAccount,
  getBankList,
  getSeller,
  getWithdrawal,
  getWithdrawalParameter,
  getBankSellerBySellerId,
  createBankSeller,
} from '@/api/channels/distributor'
import { getBusiness } from '@/api/business'
// import useQueryParam from '@/composable/useQueryParam'
import { useDateTime } from '@/composable/useDateTime'
import { useCurrency } from '@/composable/useCurrency'
import { useInputNumber } from '@/composable/useInputNumber'
import { useInput } from '@/composable/useInput'
import { columnsWithdrawal } from '@/data/columns'
import { rulesBank, rulesRequest } from '@/data/rules'
import InputNumber from '@/components/Input/InputNumber.vue'
import DateIcon from '@/components/Icons/DateV2.vue'
import { ref } from 'vue'

// const { to } = useQueryParam()
const { parseFullDate24 } = useDateTime()
const { format } = useCurrency()
const { numberOnly, toNumber } = useInputNumber()
const { lettersOnly } = useInput()
const ruleForm = ref(null)

export default {
  name: 'ListWithdrawal',
  components: {
    InputNumber,
    DateIcon,
  },
  data() {
    return {
      businessId: '',
      sellerId: '',
      ruleBank: null,
      totalRows: 0,
      result: 0,
      isLoading: false,
      fetching: false,
      visibleRequest: false,
      valueWarehouse: null,
      visibleBank: false,
      dateFormat: 'DD MMMM YYYY',
      formatDate: 'DD-MM-YYYY',
      sizeOptions: ['10', '20', '30', '40', '50'],
      screenHeight: ((screen.height - 900) * 0.36) + (screen.height * 0.36),
      withdrawals: [],
      warehouseList: [],
      accounts: [],
      filteredAccount: [],
      banks: [],
      filteredBank: [],
      columns: columnsWithdrawal,
      selectedDateFilter: [this.$moment().subtract(7, 'days'), this.$moment()],
      status: [
        { value: '', label: i18n.t('withdrawal.all') },
        { value: 'DRAFT', label: i18n.t('withdrawal.draft') },
        { value: 'CONFIRMED', label: i18n.t('withdrawal.confirmed') },
        { value: 'CANCELED', label: i18n.t('withdrawal.canceled') },
        { value: 'COMPLETED', label: i18n.t('withdrawal.completed') },
      ],
      balance: {
        addition_outstanding: 0,
        available: 0,
        current: 0,
        seller_id: '',
        subtraction_outstanding: 0,
        estimated: 0,
      },
      isBusinessId: '',
      rules: rulesRequest,
      colors: {
        APPROVED: 'blue',
        DRAFT: 'orange',
        CANCELED: 'red',
        COMPLETED: 'green',
        REJECTED: 'pink',
        PROCESSED: 'cyan',
        FAILED: 'purple',
      },
      ranges: {
        'Hari ini': [this.$moment(), this.$moment()],
        'Bulan ini': [this.$moment().startOf('month'), this.$moment().endOf('month')],
        'Tahun ini': [this.$moment().startOf('year'), this.$moment().endOf('year')],
        '7 Hari Terakhir': [this.$moment().subtract(7, 'days'), this.$moment()],
        '30 Hari Terakhir': [this.$moment().subtract(1, 'month'), this.$moment()],
        '1 Tahun Terakhir': [this.$moment().subtract(1, 'year'), this.$moment()],
      },
      form: {
        bank: undefined,
        amount: '',
        correct: [],
      },
      formBank: {
        bank_id: undefined,
        account_number: null,
        account_name: '',
      },
      params: {
        page: 1,
        limit: 10,
      },
      withdrawalParameter: null,
      mappingTimeUnit: [
        {
          value: 'MONTH(S)',
          label: this.$t('withdrawal.month'),
        },
        {
          value: 'WEEK(S)',
          label: this.$t('withdrawal.week'),
        },
        {
          value: 'DAYS(S)',
          label: this.$t('withdrawal.day'),
        },
        {
          value: 'DAY(S)',
          label: this.$t('withdrawal.day'),
        },
      ],
    }
  },
  computed: {
    business() {
      return Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
    },
    // scroll() {
    //   return this.screenHeight > 320 ? this.screenHeight : 320
    // },
    tHeight() {
      return !this.withdrawals.length
        ? '0px'
        : this.screenHeight > 320
        ? `${this.screenHeight}px`
        : '320px'
    },
    getMappingTimeUnit() {
      return this.mappingTimeUnit?.find((obj) => obj.value === this.withdrawalParameter?.frequency_time_unit)?.label
    },
  },
  watch: {
    business: {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.businessId = value
        this.fetchSeller()
      },
    },
    $route(newValue) {
      if (newValue.name === this.$route.name && !!newValue.query.warehouse_id) {
        this.fetchSeller()
      }
    },
    // permission(newValue) {
    //   if (!newValue.length) {
    //     this.$router.push({
    //       path: '/error/403',
    //       query: { ...this.$route.query },
    //     })
    //   }
    // },
  },
  mounted() {
    this.fetchBankList()
    this.fetchSeller()
    this.getWarehouseList()
    this.fetchWithdrawal()
  },
  methods: {
    handleChangeWarehouse(value) {
      this.valueWarehouse = value
      this.$router.push({
        query: { ...this.$route.query, warehouse_id: value },
      })
    },
    async getWarehouseList() {
      return await this.$store
        .dispatch('warehouse/GETWAREHOUSELIST', {
          ...this.$route.query,
        })
        .then(response => {
          this.warehouseList = response
          // return response
        })
        .catch(err => {
          console.error(err)
          return err
        })
    },
    fetchSeller: debounce(async function() {
      this.isLoading = true
      const bodyValue = {
        businessId: this.businessId,
        params: {
          warehouse_id: this.$route.query.warehouse_id,
        },
      }
      if (this.$route.query.warehouse_id) {
        await getSeller(bodyValue)
          .then(async ({ data: { data: response } }) => {
            this.sellerId = response.length ? response[0].seller_id : ''
            await this.fetchBusiness()
            this.fetchBalance()
            this.fetchWithdrawal()
            this.fetchBankSeller()
          })
          .catch(err => {
            this.sellerId = ''
            this.totalRows = 0
            this.withdrawals = []
            this.balance = {
              addition_outstanding: 0,
              available: 0,
              current: 0,
              seller_id: '',
              subtraction_outstanding: 0,
              estimated: 0,
            }
            console.error(err)
          })
          .then(() => (this.isLoading = false))
      }
      this.isLoading = false
    }, 500),

    async fetchBusiness() {
      this.isLoading = true
      const bodyValue = {
        business_id: this.$route.query.warehouse_id,
      }
      await getBusiness(bodyValue)
        .then(({ data: { data: response } }) => {
          this.isBusinessId = response[0].business_id
        })
        .catch(err => {
          this.balance = {
            addition_outstanding: 0,
            available: 0,
            current: 0,
            seller_id: '',
            subtraction_outstanding: 0,
            estimated: 0,
          }
          console.error(err)
        })
        .then(() => (this.isLoading = false))
    },
    async fetchBalance() {
      this.isLoading = true
      const bodyValue = {
        businessId: this.businessId,
        sellerId: this.sellerId,
      }
      await getBalance(bodyValue)
        .then(({ data: { data: response } }) => {
          this.balance.addition_outstanding = response.addition_outstanding
          this.balance.available = response.available
          this.balance.current = response.current
          this.balance.seller_id = response.seller_id
          this.balance.subtraction_outstanding =
            response.subtraction_outstanding
          this.balance.estimated =
            response.available + response.addition_outstanding
        })
        .catch(err => {
          this.balance = {
            addition_outstanding: 0,
            available: 0,
            current: 0,
            seller_id: '',
            subtraction_outstanding: 0,
            estimated: 0,
          }
          console.error(err)
        })
        .then(() => (this.isLoading = false))
    },

    changeTab(key) {
      this.params.status = key
      this.fetchWithdrawal()
    },

    async fetchWithdrawal() {
      this.isLoading = true
      const bodyValue = {
        businessId: this.$route.query.business_id || this.isBusinessId,
        sellerId: this.sellerId,
        params: this.params,
      }
      if (!this.params.status) {
        delete bodyValue.params.status
      }
      await getWithdrawal(bodyValue)
        .then(({ data: { data: response } }) => {
          this.totalRows = response.total_elements
          this.withdrawals = response.contents
        })
        .catch(err => {
          this.totalRows = 0
          this.withdrawals = []
          console.error(err)
        })
        .then(() => (this.isLoading = false))
    },

    async fetchBankAccount(bankList) {
      const bodyValue = {
        businessId: this.businessId,
        params: {
          page: 1,
          limit: 3000,
        },
      }
      await getBankAccount(bodyValue)
        .then(({ data: { data: response } }) => {
          let arrAccount = []
          bankList.forEach(item => {
            const findAccount = response.contents.find(account => account.id === item.account_id)
            if(findAccount) arrAccount.push(findAccount)
          })
          const distinctArrAccount = [...new Map(arrAccount.map((item) => [item["id"], item])).values()]
          this.accounts = distinctArrAccount
          this.filteredAccount = distinctArrAccount
        })
        .catch(err => {
          this.accounts = []
          this.filteredAccount = []
          console.error(err)
        })
    },
    async fetchBankSeller() {
      await getBankSellerBySellerId({ sellerId: this.sellerId })
        .then(({ data: { data: response } }) => {
          const filteredPayoutBank = response?.filter((obj) => obj?.type?.toUpperCase() === 'PAYOUT')
          this.fetchBankAccount(filteredPayoutBank || [])
        })
    },

    async fetchBankList() {
      await getBankList()
        .then(({ data: { data: response } }) => {
          this.banks = response.contents
          this.filteredBank = response.contents
        })
        .catch(err => {
          this.banks = []
          this.filteredBank = []
          console.error(err)
        })
    },

    searchAccount(value) {
      this.filteredAccount = this.accounts.filter(
        item =>
          item.bank_name.toLowerCase().includes(value.toLowerCase()) ||
          item.account_name.toLowerCase().includes(value.toLowerCase()),
      )
    },

    onChangeAccount() {
      this.filteredAccount = this.accounts
    },

    searchBank(value) {
      this.filteredBank = this.banks.filter(item =>
        item?.bank_name.toLowerCase().includes(value.toLowerCase()),
      )
    },

    onChangeBank() {
      this.filteredBank = this.banks
    },

    onChangeDate(date) {
      this.params.start_date = date[0]
        ? this.$moment(date[0]).format(this.formatDate)
        : ''
      this.params.end_date = date[1]
        ? this.$moment(date[1]).format(this.formatDate)
        : ''
      this.fetchWithdrawal()
    },

    onPageChange(pageNumber) {
      this.params.page = pageNumber
      this.fetchWithdrawal()
    },

    onShowSizeChange(current, pageSize) {
      this.params.page = current
      this.params.limit = pageSize
      this.fetchWithdrawal()
    },
    onViewHistoryWallet() {
      this.$router.push({
        name: 'finance-channel.wallet.history',
        query: {
          ...this.$route.query,
          seller_id: this.sellerId,
        },
      })
    },
    onOpenRequest() {
      this.isLoading = true
      this.visibleRequest = true
      const bodyValue = {
        businessId: this.$route.query.business_id || this.isBusinessId,
        sellerId: this.sellerId,
      }
      getWithdrawalParameter(bodyValue)
        .then(({ data: { data: response }}) => {
          this.isLoading = false
          this.withdrawalParameter = response
        })
        .catch(err => {
          this.isLoading = false
          this.withdrawalParameter = null
          console.error(err)
        })
    },
    onCancelRequest() {
      this.visibleRequest = false
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.resetFields()
        this.form.bank = undefined
        this.form.amount = ''
        this.form.correct = []
      }
    },

    onConfirmRequest() {
      ruleForm.value.validate(async valid => {
        if (valid && this.result >= 0 && (parseInt(this.form.amount.replaceAll('.', '')) >= this.withdrawalParameter?.min_amount) && parseInt(this.form.amount.replaceAll('.', '')) <= this.withdrawalParameter?.max_amount) {
          this.isLoading = true
          const {
            id: bank_id,
            bank_name,
            account_number,
            account_name,
            bank_list,
          } = this.accounts.find(item => item.id === this.form.bank)
          const bodyValue = {
            businessId: this.businessId,
            sellerId: this.sellerId,
            data: {
              bank_id,
              bank_name,
              account_number,
              account_name,
              bank_code: bank_list.bank_code2,
              seller_name: this.$store.state.user.full_name,
              amount: toNumber(this.form.amount),
              from_address: 'fed@gmail.com',
              to_address: 'pca123@mailinator.com',
            },
          }
          await addWithdrawal(bodyValue)
            .then(({ data: response }) => {
              if (response?.message_code.includes('ALL.ALL.OK')) {
                this.fetchSeller()
                this.onCancelRequest()
                this.$notification.success({
                  message: 'Berhasil',
                  description: 'Request Withdrawal Berhasil dikirim',
                })
              } else {
                // const msg = response.message_code.split('.')
                // const message = msg[msg.length - 1]
                this.$notification.error({
                  message: 'Gagal',
                  // description: $i18n.t(`error.${message}`),
                  description:
                    'Request Withdrawal Gagal dikirim Coba ulangi beberapa saat lagi',
                })
              }
            })
            .catch(err => {
              this.$notification.error({
                message: 'Gagal',
                description: 'Request Withdrawal Gagal dikirim',
              })
              console.error(err)
            })
            .then(() => (this.isLoading = false))
        }
      })
    },

    onSaveBank() {
      this.$refs.ruleBank.validate(async valid => {
        if (valid) {
          this.isLoading = true
          const bodyValue = {
            id: this.$route.params.id,
            businessId: this.businessId,
            data: {
              account_number: this.formBank.account_number,
              account_name: this.formBank.account_name,
              status: 'ACTIVE',
              bank_name: this.formBank.bank_id.label.trim(),
              bank_id: this.formBank.bank_id.key,
              name: null,
              branch: null,
              swiftCode: null,
              micrCode: null,
            },
          }
          await addBank(bodyValue)
            .then(({ data: response }) => {
              if (response.message.toLowerCase() !== 'success') {
                this.$notification.error({
                  message: 'Gagal',
                  description: response.message,
                })
              } else {
                const payload = {
                  account_id: response?.data?.id,
                  seller_id: this.sellerId,
                  is_default: true,
                  type: 'PAYOUT',
                }
                createBankSeller({
                  channelId: this.$route.params.id,
                  businessId: this.businessId,
                  payload: payload,
                })
                  .then(({ data: response }) => {
                    if (response.message.toLowerCase() === 'success') {
                      this.fetchBankSeller()
                      this.onCancelBank()
                      this.$notification.success({
                        message: 'Berhasil',
                        description: 'Daftar Bank Berhasil disimpan',
                      })
                    } else {
                      this.$notification.error({
                        message: 'Gagal',
                        description: 'Daftar Bank Gagal disimpan',
                      })
                    }
                  })
                  .catch(err => {
                    this.$notification.error({
                      message: 'Gagal',
                      description: 'Daftar Bank Gagal disimpan',
                    })
                    console.error(err)
                  })
              }
            })
            .catch(err => {
              this.$notification.error({
                message: 'Gagal',
                description: 'Daftar Bank Gagal disimpan',
              })
              console.error(err)
            })
            .finally(() => {
              this.isLoading = false
              this.$refs.ruleBank.resetFields()
            })
        }
      })
    },

    checkAmount(value) {
      this.result = +this.balance.available - value
    },

    disabledDate(current) {
      return current && current > this.$moment().endOf('day')
    },

    onAddBank() {
      this.visibleRequest = false
      this.visibleBank = true
    },

    onCancelBank() {
      this.$refs.ruleBank.resetFields()
      this.visibleBank = false
      this.visibleRequest = true
      if (this.$refs.ruleBank) {
        this.$refs.ruleBank.resetFields()
        this.formBank.bank_id = undefined
        this.formBank.account_number = null
        this.formBank.account_name = ''
      }
    },
  },
}
</script>

<script setup>
</script>

<style lang="scss">
.fw-bold {
  font-weight: bold;
}
.c-red {
  color: #f5222d;
}
.c-green {
  color: #52c41a;
}
.withdrawal-list div.ant-table-body,
.withdrawal-list div.ant-table-placeholder {
  min-height: v-bind(tHeight);
}
.add-bank {
  display: block;
  position: absolute;
  top: 10px;
  right: 0;
  font-weight: bold;
  color: #2196f3;
  cursor: pointer;
  z-index: 9;
}
.note {
  font-size: 12px;
  font-style: italic;
}
.ant-table-row-expand-icon {
  color: #2196f3 !important;
  border-color: #2196f3 !important;
  line-height: 17px !important;
}
.detail-withdrawal {
  .ant-row {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  > .ant-row {
    &:first-child {
      padding-top: 0;

      .ant-col {
        font-weight: bold;
      }
    }
  }
}
.date-container {
  .ant-calendar-picker-input {
    font-size: 14px;
    line-height: 2 !important;
    border-radius: 5px;
  }

  input {
    color: #1a1a1a;
  }

  i {
    color: #999 !important;
  }
}
.ant-calendar-range {
  &-picker-input {
    width: 45% !important;
  }

  .ant-calendar-input {
    text-align: center;
  }

  .ant-calendar-range-right .ant-calendar-date-input-wrap {
    margin-left: 0;
  }

  .ant-calendar-range-middle {
    left: 68%;
    line-height: 30px;
  }

  .ant-calendar-selected-end-date .ant-calendar-date,
  .ant-calendar-selected-start-date .ant-calendar-date {
    background: #1890ff !important;
  }

  .ant-calendar-today .ant-calendar-date {
    color: #1890ff;
    border-color: #1890ff;
  }

  .ant-calendar-in-range-cell::before {
    background: #e6f7ff !important;
  }

  .ant-calendar-disabled-cell .ant-calendar-date {
    background: #f5f5f5 !important;
  }

  .ant-calendar-footer-extra {
    line-height: 30px;

    .ant-tag {
      cursor: pointer;
    }
  }
}
.select-antd-default.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px !important;
}
.list-term {
  list-style-type: '* ';
  // list-style-position: inside;
}
.disclaimer-balance {
  font-size: 12px;
  color: #f5222d;
}
</style>
